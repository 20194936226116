#root{
	height: 100%;
}

.ant-layout{
	background: none;
}

.ant-layout-header{
	background: none;
}

.ant-layout-sider{
	background: none;
}

#home{
	background: url("assets/index_bg.png") repeat-y;
}

#home .login{
	width: 577px;
	height: 978px;
	margin: 0 auto;
	padding: 450px 0;
	background: url("assets/index_login_bg.png") no-repeat;
}

#home .ant-card.ant-card-bordered{
	background: none;
	border: none;
}

#home #mobile{
	background: none;
	margin-left: 120px;
	width: 350px;
	height: 60px;
	border: none;
	color: #fff;
}

#home .password{
	background: none;
	margin-top: 76px;
	margin-left: 120px;
	width: 370px;
}

#home .ant-col-12{
	max-width: none;
}

#home .ant-input-password{
	background: none;
	border: none;
}

#home #password{
	background: none;
	width: 350px;
	height: 50px;
	color: #fff;
}

#home .ant-input-suffix{
	display: none;
}

#home .index_button{
	margin-left: 100px;
	margin-top: 60px;
}

#home .ant-col-offset-8{
	margin-left: 0;
}

#home .index_button .ant-btn{
	width: 355px;
	height: 67px;
	background: url("assets/index_login_btn.png")  no-repeat;
	border: none;
}

#home .index_button .ant-btn:empty{
	visibility: visible;
}






#list {
	min-height: 100%;
	padding-bottom: 20px;
	background: url("assets/list_bg.png") repeat-y;
}

#list #list_content{
	margin:0 100px;
}

#list .ant-table{
	background: none;
}

#list .ant-table-container{
	border: 1px solid #3d6fb7;
}

#list th{
	background: none;
	border: 0px solid #3d6fb7;
	border-bottom: 1px solid #3d6fb7;
	color: #fff;
	padding-left: 20px;
}

#list th:first-child{
	border-right: 1px solid #3d6fb7;
	background: #050B11;
}

#list th:nth-child(2){
	border-right: 1px solid #3d6fb7;
	background: #050B11;
}

#list th:last-child{
	background: #050B11;
}

#list th:nth-last-child(2){
	background: #050B11;
}

#list td{
	background: none;
	border: 0px solid #3d6fb7;
	border-bottom: 1px solid #3d6fb7;
	color: #fff;
	padding-left: 20px;
}

#list td:first-child{
	border-right: 1px solid #3d6fb7;
	background: #050B11;
}

#list td:nth-child(2){
	border-right: 1px solid #3d6fb7;
	background: #050B11;
}

#list td:last-child{
	background: #050B11;
}

#list h4{
	color: #fff;
}

#list #logo{
	float: left;
	width: 155px;
	height: 104px;
	background: url("assets/logo.png")  no-repeat;
	margin-top: 10px;
	margin-left: 0;
}

#list #login{
	float: right;
	padding: 5px;
	color: #fff;
	margin-top: 20px;
	margin-right: 50px;
}

#list #login div{
	float: right;
	padding: 5px;
	color: #fff;
}

#list #edit{
	float: right;
	padding: 5px;
}

#list #listedit{
	display: block;
	float: right;
	margin-right: 10px;
	height: 45px;
	width: 126px;
	background: url("assets/list_btn.png")  no-repeat;
}

#list .ant-table-cell-fix-right-first::after, .ant-table-cell-fix-right-last::after{
	content: none;
}

#list #list_content .ant-collapse{
	background: #050B11;
	border: 1px solid #3d6fb7;
}

#list #list_content .ant-collapse > .ant-collapse-item{
	border-bottom: none;
}

#list #list_content .ant-collapse-header{
	color: #fff;
}

#list #list_content .ant-collapse-content{
	background: #050B11;
	border-top: 1px solid #3d6fb7;
}

#list #list_content .ant-collapse-content > .ant-collapse-content-box{
	padding: 0;
}

#list #list_content .ant-space-vertical{
	width: 100%;
}

#list #list_content .ant-space{
	display: ;
}

#list #list_content .ant-card-bordered{
	background: none;
	color: #fff;
	border: none;
}
#list #list_content .ant-card-head{
	display: none;
}

#list #list_content .ant-card-body {
	padding: 0;
}

#list #list_content .ant-card-body > .ant-row{
	border-top: 1px solid #3d6fb7;
	padding: 12px 50px;
}

#list #list_content .ant-card-body > .ant-row:first-child{
	border: none;
}

#list #list_content .ant-card-body > .ant-row .ant-col-4{
	line-height: 36px;
}

#list #list_content .ant-card-body .ant-row .ant-checkbox-wrapper{
	color: #fff;
	display: inline-block;
	width: 100px;
}

#list #hd-title{
	margin: 20px 100px 0;
	color: #fff;
	height: 40px; 
	line-height: 40px;
}

#list #edit .ant-select{
	margin-right: 30px;
}

#list #edit .ant-select-selector{
	background: #3d6fb7;
	border: #3d6fb7;
	color: #fff;
	
}

#list #button{
	background: #fff;
	color: #333;
}

#list .ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
	background: #fff;
}

#list .ant-pagination.mini .ant-pagination-item {
	margin: 0 5px;
}

#list .ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link, .ant-pagination.mini .ant-pagination-next .ant-pagination-item-link{
	background: #fff;
}

#list .ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next {
	background: #fff;
}




#info {
	min-height: 100%;
	padding-bottom: 20px;
	background: url("assets/info_bg.png") repeat-y;
}

#info #info_content{
	margin:20px;
	clear: both;
}

#info #info_sider{

}

#info td{
	color: #fff;
}

#info .ant-layout-content{
	margin-left: 10px;
}

#info .ant-layout-sider{
	border: 1px solid #3d6fb7;
	padding: 10px;
}

#info .ant-list-item-meta-title a{
	color: #fff;
}

#info .ant-list-item-meta-description{
	color: #fff;
}

#info .ant-descriptions-view th{
	display: none;
}

#info div#info_table .ant-table{
	background: none;
	color: #fff;
	border: 1px solid #3d6fb7;
	border-bottom: none;
}

#info div#info_table th{
	background: none;
	text-align: center;
	border-bottom: 1px solid #3d6fb7;
	color: #fff;
}

#info div#info_table td{
	background: none;
	text-align: center;
	border-bottom: 1px solid #3d6fb7;
}

#info #downbtn{
	display: block;
	height: 46px;
	width: 129px;
	background: url("assets/info_btn_2.png") center center  no-repeat;
}

#info #infoedit{
	display: block;
	height: 44px;
	width: 129px;
	background: url("assets/info_btn_3.png") center center  no-repeat;
}

#info .ant-descriptions-bordered .ant-descriptions-view{
	border: 1px solid #3d6fb7;
}

#info #infocontent .ant-descriptions-view{
	border-bottom: none;
}

#info .ant-descriptions-bordered .ant-descriptions-row{
	border: 1px solid #3d6fb7;
}

#info .ant-descriptions-bordered .ant-descriptions-item-label, .ant-descriptions-bordered .ant-descriptions-item-content{
	border: 1px solid #3d6fb7;
}

#info .ant-list-item{
	border: none;
}

#info .ant-list-item a{
	color: #999;
}

#info .ant-list-item-meta-description{
	color: #999;
}

#info #info_image{

}

#info .ant-input-affix-wrapper{
	border-radius: 30px;
}

#info .ant-input-affix-wrapper span{
	color: #3d6fb7;
	margin-right: 5px;
}

#info .ant-input-affix-wrapper input{
	font-size: 12px;
}


















#download{
	background: url("assets/down_bg.png")  repeat-y;
	text-align: center;
	color: #fff;
	padding: 0 50px;
	min-width: 450px;
}

#download #down_content div{
	margin-top: 10px;
}

#download div#NavH5{
	margin-top: 0;
}

#download div#Bottom{
	background: url("assets/down_bottom.png") center center  no-repeat;
	height: 166px;
	line-height: 166px;
}

#download div#hr{
	margin-top: 96px;
	background: url("assets/down_hr.png") center center  no-repeat;
	height: 5px;
}

#download div#down_title{
	text-align: left;
	margin-top: 0;
	height: 110px;
	line-height: 110px;
}

#download div#down_table {
	margin-top: 0;
}

#download div#down_table .ant-table{
	background: none;
	color: #fff;
	border: 1px solid #3d6fb7;
	border-bottom: none;
}

#download div#down_table thead{
	display: none;
}

#download div#down_table td{
	background: none;
	text-align: center;
	border-bottom: 1px solid #3d6fb7;
}

#download div#QRCode canvas{
	border: 4px solid #fff;
}

#download div#QRCode svg{
	border: 4px solid #fff;
}

#download div#QRCode svg image{

}









#Nav{

}

#Nav .ant-layout-header{
	height: 120px;
	padding: 0 150px 0 70px;
}

#Nav #logo{
	float: left;
	width: 155px;
	height: 104px;
	background: url("assets/logo.png")  no-repeat;
	margin-top: 10px;
	margin-left: 0;
}

#Nav #login{
	float: right;
	padding: 5px;
	color: #fff;
	margin-top: 20px;
	margin-right: 50px;
}

#Nav #login div{
	float: right;
	padding: 5px;
	color: #fff;
}

#Nav #header-content{
	float: left;
	color: #fff;
	margin-left: 100px;
	font-size: 42px;
	height: 128px;
	line-height: 128px;
}

#Nav #header-content a{
	display: inline-block;
	margin-right: 50px;
}






#NavH5{
	
}

#NavH5 .ant-layout-header{
	height: 120px;
	padding: 0;
}

#NavH5 #logo{
	float: left;
	margin-top: 10px;
	margin-left: 0;
}

#NavH5 #logo img{
	width: 10rem;
	height: 6.25rem;
}


#clear {
	clear: both;
}



#list ::-webkit-scrollbar {
	width: 0px; /*对垂直流动条有效*/
	height: 10px; /*对水平流动条有效*/
}

#info ::-webkit-scrollbar {
	height: 10px; /*对水平流动条有效*/
}

/*定义滚动条的轨道颜色、内阴影及圆角*/
::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: none;
	border-radius: 3px;
}


/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb{
	border-radius: 7px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #3d6fb7;
}

/*定义两端按钮的样式*/
::-webkit-scrollbar-button {
	background-color: none;
}

/*定义右下角汇合处的样式*/
::-webkit-scrollbar-corner {
	background: none;
}